@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$green-trip-advisor: #00af87 !default;

.tripadvisor {
	display: flex;
	align-items: center;
	cursor: pointer;

	&__rating {
		display: flex;

		img {
			height: 15px;
			margin-left: -7px; // le logo trip advisor a des espaces sur les cotés

			@include gridle_state(md) {
				height: 14px;
			}
		}
	}

	&__reviews {
		display: flex;
		align-items: center;
		color: $green-trip-advisor;
		text-decoration: underline;
	}

	&__icon-detail {
		width: 14px;
		height: 10px;

		@include gridle_state(md) {
			margin-top: 1px;
		}
	}

	&__portal {
		.ReactModal {
			&__Overlay {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: rgba(43, 56, 63, 0.9) !important;
				z-index: 1001;
			}

			&__Content {
				margin: 0 auto;
				inset: 0 !important;
				padding: 32px 24px !important;
				border-radius: 0 !important;
				z-index: 1002;

				@include gridle_state(md) {
					max-height: 85vh;
					inset: unset !important;
					position: relative !important;
					border-radius: $border-radius-small !important;
					padding: 20px 40px 40px !important;
					width: 752px;
				}
			}
		}
	}

	&-modal {
		&__head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 40px;
		}

		&__title {
			.product-name-with-category__name {
				@extend %text-bold;

				padding-right: 16px;
				font-size: 2.4rem;
				letter-spacing: -0.24px;

				@include gridle_state(md) {
					margin-right: 0;
					padding-right: unset;
				}
			}
		}

		&__content {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__close {
			position: absolute;
			top: 16px;
			right: 16px;
			width: 16px;
			height: 16px;
			cursor: pointer;
		}
	}
}
